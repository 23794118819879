import React, { useEffect, useState } from "react"
import {
  Form,
  Input,
  Button,
  Checkbox,
  DatePicker,
  Select,
  Upload,
  message,
  Row,
  Col,
  Radio,
  Steps,
  Space,
  Result,
  Typography,
} from "antd"
import {
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons"
import Logo from "../digicust_logo.png"
import { RcFile } from "antd/es/upload"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import axios from "axios"
import { useParams } from "react-router-dom"
import DCTooltip from "../components/Tooltip"
import { useTranslation } from "react-i18next"

const { Option } = Select
const { TextArea } = Input
const { Step } = Steps

export const ExportForm = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const [form] = Form.useForm()
  const [current, setCurrent] = useState(0)
  const [type, setType] = useState("private")
  const [differingRecipient, setDifferingRecipient] = useState(false)
  const [differingPlaceOfPresentation, setDifferingPlaceOfPresentation] =
    useState(false)
  const [captchaToken, setCaptchaToken] = useState(null)

  const [fileList, setFileList] = useState<RcFile[]>([])
  const { t, i18n } = useTranslation()

  const [widgetConfiguration, setWidgetConfiguration] = useState<any>({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.digicust.com/generic/api/website/widget/${id}`
        )
        const data = response.data
        setWidgetConfiguration(data)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
    fetchData()
  }, [id])

  useEffect(() => {
    if (widgetConfiguration?.defaultLanguage) {
      i18n.changeLanguage(widgetConfiguration.defaultLanguage)
    }
  }, [widgetConfiguration, i18n])

  const handleFinish = async (values: any) => {
    setLoading(true)
    console.log("Form values:", values)

    const formData = new FormData()

    fileList.forEach((file) => {
      formData.append("files[]", file)
    })

    Object.keys(values).forEach((key) => {
      if (Array.isArray(values[key])) {
        values[key].forEach((item: any, index: number) => {
          Object.keys(item).forEach((subKey) => {
            formData.append(`${key}[${index}][${subKey}]`, item[subKey])
          })
        })
      } else {
        formData.append(key, values[key])
      }
    })

    if (captchaToken) {
      formData.append("g-recaptcha-response", captchaToken)
    }

    formData.append("id", id || "")

    try {
      await axios.post(
        "https://api.digicust.com/generic/api/website/widget/upload",
        formData
      )

      message.success(t("Form submitted successfully!"))
      setFinished(true)
    } catch (error) {
      console.error("Failed to submit form:", error)
      message.error(t("Failed to submit the form!"))
    }
    setLoading(false)
  }

  const handleFinishFailed = (errorInfo: any) => {
    console.log("Form failed:", errorInfo)
    message.error(errorInfo?.errorFields?.[0]?.errors?.[0])
  }

  const steps = [t("Your Details"), t("Shipment"), t("Items & Confirmation")]

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  return finished ? (
    <Result
      status='success'
      title={t("Export declaration successfully requested!")}
      subTitle=''
      extra={[
        <Button
          onClick={() => {
            setFinished(false)
            form.resetFields()
            setCurrent(0)
            setLoading(false)
          }}
          key='another'>
          {t("Submit Another Declaration")}
        </Button>,
      ]}
    />
  ) : (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
        background: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Radio.Group
          style={{ marginBottom: "20px" }}
          value={type}
          onChange={(e) => setType(e.target.value)}>
          <Radio.Button value='private'>{t("Private")}</Radio.Button>
          <Radio.Button value='commercial'>{t("For Business")}</Radio.Button>
        </Radio.Group>

        <span style={{ marginTop: "-20px", marginLeft: "20px" }}>
          {t("Request export declaration in 3 steps")}
        </span>
        <Select
          style={{ marginBottom: "20px", marginLeft: "auto" }}
          popupMatchSelectWidth={false}
          onChange={(lang) => {
            console.log(lang)
            i18n.changeLanguage(lang)
            // window.location.reload()
          }}
          value={i18n.language}
          options={[
            { label: "English", value: "en" },
            { label: "German", value: "de" },
            { label: "Spanish", value: "es" },
            { label: "Albanian", value: "sq" },
            { label: "Dutch", value: "nl" },
            { label: "French", value: "fr" },
            { label: "Italian", value: "it" },
            { label: "Turkish", value: "tr" },
          ]}
        />
      </div>
      <Steps onChange={(value) => setCurrent(value)} current={current}>
        {steps.map((title) => (
          <Step key={title} title={title} />
        ))}
      </Steps>
      <Form
        form={form}
        layout='vertical'
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        style={{ marginTop: "20px" }}>
        <div style={{ display: current === 0 ? "block" : "none" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t("Invoices, Packing Lists, Delivery Notes etc.")}
                name='invoices'
                rules={[
                  {
                    required: true,
                    message: t("Please upload the invoices"),
                  },
                ]}>
                <Upload
                  multiple={true}
                  accept='.pdf'
                  beforeUpload={(file, fileList) => {
                    setFileList(fileList)
                    return false
                  }}>
                  <Button icon={<UploadOutlined />}>{t("Upload")}</Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t("Your Name")}
                name='name'
                rules={[
                  {
                    required: true,
                    message: t("Please enter your name"),
                  },
                ]}>
                <Input placeholder={t("John Doe")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {type === "commercial" && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={t("Your Company")}
                    rules={[
                      {
                        required: true,
                        message: t("Please enter the company name"),
                      },
                    ]}
                    name='company'>
                    <Input placeholder={t("Example Company Ltd")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: t("Please enter your EORI number"),
                    //   },
                    // ]}
                    label={
                      <>
                        {t("Your EORI Number")}{" "}
                        <DCTooltip
                          text={t(
                            "The Economic Operators Registration and Identification (EORI) number is a unique ID assigned to businesses that import or export goods to or from the EU. If you don't have one yet, you can apply for it."
                          )}
                        />
                        <a
                          target='_blank'
                          href='https://www.zoll.de/DE/Fachthemen/Zoelle/EORI-Nummer/Beantragung-einer-EORI-Nummer/beantragung-einer-eori-nummer_node.html'>
                          {t("Apply for EORI")}
                        </a>
                      </>
                    }
                    name='eori'>
                    <Input placeholder='DE12345678912345' />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t("Your Email")}
                name='email'
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: t("Please enter a valid email address"),
                  },
                ]}>
                <Input placeholder='john@example.com' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <>
                    {t("Your Phone Number")}
                    <DCTooltip
                      text={t(
                        "Please enter your phone number. That way, we can reach you if we have any questions regarding the shipment."
                      )}
                    />
                  </>
                }
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: t(
                      "Please enter your phone number. That way, we can reach you if we have any questions regarding the shipment."
                    ),
                  },
                ]}
                name='phone'>
                <Input placeholder='+49 234 567 890' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t("Cannot be empty"),
                  },
                ]}
                label={t("Your Address")}
                name='address'>
                <TextArea
                  placeholder={t(
                    "123 Example Street, 12345 Example City, Country"
                  )}
                  rows={3}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ marginBottom: "-1px" }}>
                <Checkbox
                  checked={differingRecipient}
                  onChange={(e) => setDifferingRecipient(e.target.checked)}>
                  {t("Recipient different from invoice")}
                  <DCTooltip
                    text={t(
                      "Normally we take the recipient address from the commercial invoice you attach to this form. However, if the address differs or is not present on the attached documents, you can specify a different address here."
                    )}
                  />
                </Checkbox>
              </Form.Item>
              {differingRecipient && (
                <>
                  <Form.Item
                    name='recipientName'
                    style={{ marginBottom: "5px" }}>
                    <TextArea rows={1} placeholder={t("Recipient Name")} />
                  </Form.Item>
                  <Form.Item name='recipientAddress'>
                    <TextArea
                      rows={2}
                      placeholder={t("Street, Number, City, Postal Code")}
                    />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>
        </div>

        <div style={{ display: current === 1 ? "block" : "none" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t("Transportation")}
                name='transportType'
                rules={[
                  {
                    required: true,
                    message: t("Please select the type of transportation"),
                  },
                ]}>
                <Select placeholder={t("Type of Transportation")}>
                  <Option value='land'>{t("Land Transport (Truck)")}</Option>
                  <Option value='sea'>{t("Sea Freight (Ship)")}</Option>
                  <Option value='air'>{t("Air Freight (Plane)")}</Option>
                  <Option value='post'>{t("Post")}</Option>
                  <Option value='ownDrive'>{t("Own drive")}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("Customs Office of Exit")}
                name='exitCustomsOffice'
                rules={[
                  {
                    required: true,
                    message: t("Please select the customs office of exit"),
                  },
                ]}>
                <Input placeholder='DE004600' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ marginBottom: "-1px" }}>
                <Checkbox
                  checked={differingPlaceOfPresentation}
                  onChange={(e) =>
                    setDifferingPlaceOfPresentation(e.target.checked)
                  }>
                  {t("Place of presentation different from your address")}
                </Checkbox>
              </Form.Item>
              {differingPlaceOfPresentation && (
                <>
                  <Form.Item name='placeOfPresentation'>
                    <TextArea
                      rows={2}
                      placeholder={t("Street, Number, City, Postal Code")}
                    />
                  </Form.Item>
                </>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <>
                    {t("Presentation Date")}{" "}
                    <Typography.Text style={{ marginLeft: 5 }} type='secondary'>
                      {" "}
                      {t("(Usually 2h)")}
                    </Typography.Text>{" "}
                    <DCTooltip
                      text={t(
                        "The notification of time and place is required so customs authorities can properly exercise their control rights - both for enforcing customs law and checking compliance with other applicable regulations (like restrictions and prohibitions). This allows customs to perform necessary inspections and verify that goods are being properly brought into the EU customs territory."
                      )}
                    />
                  </>
                }
                name='presentationDate'
                rules={[
                  {
                    required: true,
                    message: t("Please select the presentation date"),
                  },
                ]}>
                <DatePicker showTime />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ display: current === 2 ? "block" : "none" }}>
          <Form.Item
            label={t(
              "Items (only if tariff number or description is missing from uploaded documents)"
            )}
            name='items'>
            <Form.List name='items'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row gutter={16} key={key}>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          name={[name, "articleNumber"]}
                          rules={[
                            {
                              required: true,
                              message: t("Article number required"),
                            },
                          ]}>
                          <Input placeholder={t("Article Number")} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          tooltip={t("test")}
                          name={[name, "tariffNumber"]}>
                          <Input placeholder={t("Tariff Number")} />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item {...restField} name={[name, "description"]}>
                          <Input
                            placeholder={t("Description/Purpose of Use")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <MinusCircleOutlined
                          style={{ margin: "8px 0" }}
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}>
                      {t("Add Item")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item
            label={t("Package Information (if not on uploaded documents)")}
            name='packaging'>
            <TextArea
              rows={2}
              placeholder={t(
                "Please indicate how many packages are needed to ship your goods and how these individual packages are packed. (e.g., 2 pallets, 3 boxes, and 4 items unpacked)"
              )}
            />
          </Form.Item>
          <p>{t(widgetConfiguration?.terms)}</p>
          <Form.Item
            style={{ marginBottom: "5px" }}
            name='confirmation'
            valuePropName='checked'
            rules={[
              {
                validator: async (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          t("Please confirm terms and customs authorization")
                        )
                      ),
              },
            ]}>
            <Checkbox>
              {t(
                "I have read the terms and conditions and confirm the customs authorization."
              )}
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <GoogleReCaptcha
              onVerify={(value) => setCaptchaToken(value as any)}
            />
          </Form.Item>
        </div>

        <Space direction='horizontal'>
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              {t("Back")}
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type='primary' onClick={() => next()}>
              {t("Next")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                loading={loading}
                disabled={loading}
                type='primary'
                htmlType='submit'>
                {t("Submit Export Declaration")}
              </Button>
            </Form.Item>
          )}
        </Space>
      </Form>
      <div
        style={{
          marginTop: "-20px",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}>
        {t("Powered by")}{" "}
        <a target='_blank' href='https://digicust.com'>
          <img
            style={{
              marginTop: "10px",
              width: "100px",
              marginLeft: "10px",
            }}
            src={Logo}
            alt='Digicust Logo'
          />
        </a>
      </div>
    </div>
  )
}
